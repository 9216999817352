<template>
  <BaseDialog v-model="open" size-class="max-w-4xl h-[60vh]">
    <template #header>Notes Management</template>
    <template #default>
      <div class="flex flex-col gap-4">
        <div v-for="note in notes" :key="note.id" class="flex items-start justify-between">
          <div class="flex flex-1 flex-row gap-2">
            <BaseSelect v-model="note.dataset" :options="datasets" placeholder="Select a dataset" portal searchable />
            <BaseTextbox v-model="note.note" class="flex-1" />

            <BaseButton size="xs" @press="editNote(note)">Save</BaseButton>
            <BaseButton size="xs" type="danger" @press="deleteNote(note.id)">Delete</BaseButton>
          </div>
        </div>
        <div class="mt-4 flex flex-row gap-2">
          <BaseSelect v-model="selectedDataset" :options="datasets" placeholder="Select a dataset" portal searchable />
          <BaseTextbox v-model="newNoteContent" placeholder="Add a new note" class="flex-1" />
          <BaseButton @press="addNote">Add Note</BaseButton>
        </div>
      </div>
    </template>
    <template #footer>
      <BaseButton class="ml-auto" size="sm" @press="open = false">Close</BaseButton>
    </template>
  </BaseDialog>
</template>

<script setup>
import { ref, watch, computed } from 'vue';
import useHttp from '@/composeables/http';

const props = defineProps({
  modelValue: Boolean
});

const emit = defineEmits(['update:modelValue']);

const open = computed({
  get: () => props.modelValue,
  set: value => emit('update:modelValue', value)
});

const notes = ref([]);
const newNoteContent = ref('');
const selectedDataset = ref('');
const datasets = ref([]);
const $http = useHttp();

watch(open, async () => {
  if (open.value) {
    await fetchNotes();
    await fetchDatasets();
  }
});

async function fetchNotes() {
  await new Promise(resolve => setTimeout(resolve, 1000));
  const { data: response } = await $http.get('/dataset_notes');
  notes.value = response.data;
}

async function fetchDatasets() {
  const { data: response } = await $http.get(`/dataset_gpt/catalog`);
  datasets.value = response.data.map(dataset => ({ id: dataset, label: dataset }));
}

async function addNote() {
  if (selectedDataset.value) {
    const { data: response } = await $http.post('/dataset_notes', {
      note: newNoteContent.value,
      dataset: selectedDataset.value
    });
    newNoteContent.value = '';
    selectedDataset.value = '';
    await fetchNotes();
  } else {
    alert('Please select a dataset.');
  }
}

async function editNote(note) {
  if (note.note && note.dataset) {
    const { data: response } = await $http.put(`/dataset_notes/${note.id}`, {
      note: note.note,
      dataset: note.dataset
    });
    await fetchNotes();
  } else {
    alert('Please ensure all fields are filled.');
  }
}

async function deleteNote(noteId) {
  if (confirm('Are you sure you want to delete this note?')) {
    await $http.delete(`/dataset_notes/${noteId}`);
    await fetchNotes();
  }
}
</script>
